<template>
    <div class="comments">
        <div class="comments-label">
            <p>Комментарии менеджера</p>
            <p v-if="getFormattedLastComment">последний:</p>
            <!-- пока не реализовано получение этих параметров из managerComment -->
            <!-- <p v-if="getFormattedLastComment">{{ getFormattedLastComment.date }}</p> -->
            <!-- <p v-if="getFormattedLastComment">от {{ getFormattedLastComment.name }}</p> -->
            <p v-else>(пока нет комментариев)</p>
        </div>
        <div class="comments-content">
            <div v-if="getFormattedLastComment" class="comments-last-comment">
                {{ getFormattedLastComment.text }}
            </div>
            <div class="comments-buttons">
                <button
                    type="button"
                    :disabled="isAddCommentDisabled"
                    @click="onClickInput"
                >
                    Оставить комментарий
                </button>
                <button type="button" @click="onClickHistory">История</button>
            </div>
        </div>
        <modal
            name="comments-input-modal"
            styles="border-radius: 12px;"
            width="535px"
            height="auto"
            :adaptive="true"
        >
            <div class="comments-input-wrapper">
                <div class="comments-input-title">
                    Оставить комментарий менеджера
                </div>
                <TextArea
                    name="managerComment"
                    :valid="true"
                    :touched="true"
                    :shouldValidate="false"
                    :hasLabel="false"
                    @change="onChange"
                    @blur="onChange"
                />
                <div class="comments-input-buttons">
                    <Button @click="onClickCancel">Отмена</Button>
                    <Button :disabled="!commentText" @click="onClickSave"
                        >Сохранить</Button
                    >
                </div>
            </div>
        </modal>
        <modal
            name="comments-history-modal"
            styles="border-radius: 12px;"
            width="645px"
            height="645px"
            :adaptive="true"
        >
            <div class="comments-history-wrapper">
                <div class="comments-history-title">История комментариев</div>
                <div
                    v-if="isLoaded && getFormattedSortedComments.length"
                    class="comments-history-items"
                >
                    <div
                        v-for="(item, index) in getFormattedSortedComments"
                        :key="index"
                        class="comments-item"
                    >
                        <div class="comments-item-label">
                            <p>{{ item.date }}</p>
                            <p>{{ item.name }}</p>
                            <p>{{ item.position }}</p>
                        </div>
                        <div class="comments-item-text">{{ item.text }}</div>
                    </div>
                </div>
                <div
                    v-if="isLoaded && !getFormattedSortedComments.length"
                    class="comments-history-empty"
                >
                    Пока нет ни одного комментария
                </div>
                <div v-if="!isLoaded" class="loader">
                    <Loader />
                </div>
                <div class="comments-history-buttons">
                    <Button @click="onClickClose">Закрыть</Button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import TextArea from '@/components/UI/form/TextArea.vue';
import Button from '@/components/UI/Button.vue';
import Loader from '@/components/UI/Loader.vue';

export default {
    components: {
        TextArea,
        Button,
        Loader,
    },
    props: {
        type: {
            type: String,
            default: 'App',
            required: true,
        },
        orderId: {
            type: [Number, String],
            required: false, // Может быть undefined, в случае, если заявка создаётся в момент просмотра комментариев
        },
        isAddCommentDisabled: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            items: [],
            commentText: '',
        };
    },

    computed: {
        ...mapState('comments', ['isLoaded']),
        ...mapGetters('comments', [
            'getFormattedSortedComments',
            'getFormattedLastComment',
        ]),
    },

    methods: {
        ...mapActions('comments', ['getComments', 'addComment']),

        onClickInput() {
            this.$modal.show('comments-input-modal');
        },

        onClickHistory() {
            this.getComments({ type: this.type, id: this.orderId });
            this.$modal.show('comments-history-modal');
        },

        onClickCancel() {
            this.commentText = '';
            this.$modal.hide('comments-input-modal');
        },

        onClickSave() {
            this.addComment({
                type: this.type,
                orderId: this.orderId,
                commentText: this.commentText,
            });
            this.commentText = '';
            this.$modal.hide('comments-input-modal');
        },

        onClickClose() {
            this.$modal.hide('comments-history-modal');
        },

        onChange(event) {
            this.commentText = event.target.value.trim();
        },
    },
};
</script>

<style lang="scss">
.comments {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.comments-label {
    width: calc(50% - 5px);
    font-size: 14px;
    color: #808080;
    margin-top: 10px;
    margin-right: 10px;
    p {
        margin: 0;
        padding-bottom: 2px;
    }
    p:nth-child(1) {
        padding-bottom: 5px;
    }
}

.comments-content {
    margin-top: 10px;
    width: calc(50% - 5px);
    font-size: 14px;
    color: #333;
}

.comments-buttons {
    display: grid;
    grid-template-columns: 200px 100px;

    button {
        border: none;
        background: none;
        margin-bottom: 5px;
        margin-right: 2em;
        padding: 0;
        text-align: left;
        cursor: pointer;
        &:hover {
            color: #6f61e9;
        }
    }
}

.comments-last-comment {
    min-height: 83px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    color: #333;
    background: #f6f6f8;
    border: 1px solid transparent;
    margin-bottom: 5px;
    padding: 10px 12px;
    word-wrap: break-word;
}

.comments-input-wrapper {
    width: 100%;
    padding: 45px;
    text-align: center;
}

.comments-input-title,
.comments-history-title {
    font-size: 20px;
    font-weight: 700;
    color: #333;
    padding-bottom: 15px;
}
.comments-history-title {
    padding-bottom: 25px;
}

.comments-input-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;

    &.single {
        justify-content: center;
    }
}

.comments-history-wrapper {
    padding: 45px 5px;
    height: 100%;
    text-align: center;
}

.comments-history-items {
    height: calc(100% - 118px);
    overflow-y: scroll;
    padding: 0px 40px 25px 40px;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background: #6f61e9;
        border-radius: 3px;
    }
}

.comments-history-empty {
    height: 437px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comments-history-buttons {
    padding-top: 25px;
}

.comments-item {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    &-label {
        width: calc(50% - 5px);
        margin-top: 10px;
        margin-right: 10px;
        text-align: left;
        font-size: 14px;
        color: #808080;
        p {
            margin: 1px 0;
        }
    }
    &-text {
        width: calc(50% - 5px);
        background: #fff;
        border: 1px solid #e0dfee;
        border-radius: 4px;
        text-align: left;
        font-size: 14px;
        line-height: 22px;
        color: #333;
        padding: 9px 12px;
        word-wrap: break-word;
    }
}

.loader {
    height: 437px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
