









import Vue from "vue";

export default Vue.extend({
  name: "ComboWrapper",
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
});
